@import 'global/colors';
@import 'global/variables';
@import 'global/globals';
@import 'global/footer';
@import 'global/header';
@import 'global/sub_header';


@import 'dev-tools/left_block';
@import 'dev-tools/right_block';
@import 'dev-tools/modal';
@import 'dev-tools/main';

@import 'index/main';
@import 'index/calculator_blocks';
@import 'index/about_us';
@import 'index/contact_us';

//user registration style sheets
@import 'users/registration';
@import 'users/info';
@import 'users/calculators';
@import 'users/billinginfo';
@import 'users/done';

@import 'account/main';