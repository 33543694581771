#main-wrap{
    #left-block-wrap{
        position: fixed;
        top: 115px;
        width: 450px;
        padding: 20px 30px;
        float: left;
        #left-block-header{
            padding-bottom: 10px;
        }
        #left-block-body{
            p{
                font-size: 14px;
                font-family: 'Lato', san-serif;
                font-weight: 300;
                color: $body_gray;

            }

        }
        #left-block-footer{
            padding-top: 10px;
            p{
                font-size: 14px;
                font-family: 'Lato', san-serif;
                font-weight: 300;

                color: $body_gray;
            }
        }
    }
}