#about-us{
	background-color: #0076B6;
	color: white;
	padding: 40px 50px;
	border-top: 10px solid #DDDDDD;
	position: relative;
	border-bottom: 15px solid #F0F2F4;
	#about-us-header{
		font-family: Helvetica;
		font-size: 24px;
		padding-bottom: 10px;
	}
	div#about-us-body {
	}
	div#mac-box {
		float: right;
		width: 274px;
		height: 288px;
		margin-left: 2em;
		margin-bottom: -107px;
		background-size: contain;
		background-repeat: no-repeat;
		position: relative;
		top: -117px;
	}
	@media screen and (max-width: $desktop-width) {
		#about-us-header{
			position: relative;
			top: 258px;
			text-align: center;
		}
		div#about-us-body {
			margin-top: 0;
		}
		div#mac-box {
			float: none;
			margin: 0 auto;
			text-align: center;
		}
	}
}
