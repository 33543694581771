.reg-done {

  div:nth-of-type(1) {
    p {
      width: 493px;
      margin: 0 auto 22px auto;
      font-weight: 100;
    }
  }

  div:nth-of-type(2){
    margin: 0 auto 75px auto;
    width: 176px;
    .btn {
      width: 100%;
      text-align: center;
      background: none;
      border: solid 1px $header_background;
      color: $header_background;
      padding: 8px 0 0 0;
      transition: color 1s, background-color 1s;
      -webkit-transition: color 1s, background-color 1s;
      -moz-transition: color 1s, background-color 1s;
      &:hover {
        color: #fff;
        background-color: $header_background;
      }
    }
  }

}