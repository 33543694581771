#main-wrap{
    #right-block-wrap{
        float: right;
        width: calc(100% - 445px);
        padding: 30px 70px;
        z-index: 0;
        margin-top: 140px;
        margin-bottom: 90px;
        padding-right:0;
        #bottom-fader{
            opacity: 0;
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
            width: 471px;
            height: 45px;
            position: fixed;
            bottom: 106px;
            transition: .5s ease;
            &.under{
                opacity: 1;
            }
        }
        .widget-box-wrap{
            padding-top: 10px;
            width: 50%;
            float: left;
            .widget-box{
                width: 228px;
                height: 221px;
                background: white;
                position: relative;

                .widget-box-header{
                    width:100%;
                    padding-top: 10px;
                    text-align: center;
                    height: 60px;
                }
                .widget-box-body{
                    font-size: 12px;
                    color: #5B5B5B;
                    padding: 17px;
                    padding-top: 15px;
                    padding-bottom: 0px;
                }
                .widget-box-button{
                    width: 197px;
                    height: 35px;
                    position: absolute;
                    bottom: 14px;
                    left: 17px;
                     .btn{
                        background: transparent;
                         width: 100%;
                         height:100%;
                         border-radius: 0;
                     }
                }
                &.orange{
                    border: 2px solid #CC844A;

                    .btn{
                        border: 1px solid #CC844A;
                        color: #CC844A;
                        transition: .5s;
                        outline-color: #CC844A;
                        &:hover{
                            background:rgba(204, 132, 74, 0.15);
                        }
                    }
                }
                &.red{
                    border: 2px solid #C2332C;

                    .btn{
                        border: 1px solid #CBCDCD;
                        color: #CBCDCD;
                    }
                }
                &.dark-red{
                    border: 2px solid #BE1624;

                    .btn{
                        border: 1px solid #CBCDCD;
                        color: #CBCDCD;
                    }
                }
                &.purple{
                    border: 2px solid #4A266D;

                    .btn{
                        border: 1px solid #CBCDCD;
                        color: #CBCDCD;
                    }
                }
                &.green{
                    border: 2px solid #5B5B5B;

                    .btn{
                        border: 1px solid #CBCDCD;
                        color: #CBCDCD;
                    }
                }
                &.light-green{
                    border: 2px solid #557A37;

                    .btn {
                        border: 1px solid #CBCDCD;
                        color: #CBCDCD;
                    }
                }
            }
        }

    }
}

// Image Configs

img{
    &.img01{
        width: 197px;
        height: 55px;
    }
    &.img02{
        width: 160px ;
        height: 54px ;
    }
    &.img03{
        width: 184px;
        height: 32px;
    }
    &.img04{
        width: 153px ;
        height: 47px ;
    }
    &.img05{
        width: 163px;
        height: 37px;
    }
    &.img06{
        width: 166px;
        height: 33px;
    }
}