#paymortgagefast-modal {
    top: 15%;
    .modal-dialog {
        width: 660px;
        height: 500px;
        top: 15%;
        .modal-content {
            border-radius: 0;
            border: 2px solid #0076B6;
            background-color: #F0F2F4;
            .modal-header {
                padding: 0;
                border: 0;
                .close-btn {
                    position: absolute;
                    top: -32px;
                    right: 0px;
                    border-radius: 0;
                    color: white;
                    background: #0076B6;
                    border: 0;
                    padding: 6px 29px;
                }
            }
            .modal-body{
                #widget-modal-left{
                    float: left;
                    width: 45%;
                }
                #widget-modal-right{
                    float: right;
                    width: 55%;
                    padding-right: 50px;
                    h3{
                        color: #4A4A4A;
                    }
                    span{
                        clear: both;
                        display: block;
                        color: #999999;
                        padding-bottom: 15px;
                    }
                    p{
                        color: #999999;
                        padding-bottom: 25px;
                    }
                    .btn-group{
                        display:block;
                        .btn-blue{
                            width: 91px;
                            outline: 0;
                            font-size: 12px;
                            &:first-child{
                                border-right: 0;

                            }
                            &:last-child{
                                border-left: 0;
                            }
                        }
                        .active{
                            height: 39px;
                            width: 90px;
                            border-radius: 0px;
                            background: $header_background;
                            color: white;
                            vertical-align: top;
                            font-size: 12px;
                            transition: 1s ease;
                        }
                    }
                    .widget-code-label{
                        padding-top: 30px;
                    }
                    textarea{
                        width: 100%;
                        height: 105px;
                    }
                    #modal-right-footer{
                        padding-top: 30px;
                        .btn-blue.outline{
                            float: right;

                        }
                    }
                }

            }
            height: 100%;
        }
    }
}