.edit-block.scenario-block {
	min-height: 886px;

	.headCol {
		border-bottom: none;
	}
	.case-container {
		width: calc(100% - 40px);
		position: relative;
		clear: both;
		margin: 20px;
		border: 1px solid #DBDBDB;
		max-height: 46px;
		overflow:hidden;
		transition: .5s;
		.case-container-header {
			width: 100%;
			position: relative;
			cursor:pointer;
			.calculator-logo {
				background: #FFF no-repeat center center / contain;
				height: 50px;
				width: 153px;
				clear: both;
				float: left;
				margin: 5px;
				margin-top: 7px;
			}
			.case-count {
				text-align: right;
				width: calc(100% - 163px);
				float: right;
				font-weight: 100;
				font-size: 12px;
				padding: 14px 46px;
				background: #EFEFEF;
				color: #107E98;
			}
		}
		&.active {
			border: 1px solid #1A7E97;
			height: initial;
			max-height: 2500px;
			.case-container-header {
				.case-count {
					background: #107E98;
					color: #EFEFEF;
				}
			}
		}
		.case-block-wrap {
			position: relative;
			clear: both;
			padding: 30px 15px;
			.name {
				padding: 12px 20px 30px 10px;
				color: #A6A6A6;
			}
			.case-block {
				max-height: 53px;
				padding: 10px 0;
				transition: .5s;
				overflow:hidden;
				.case-block-header {
					background: #EFEFEF;
					padding: 12px 20px 30px 10px;
					cursor: pointer;
					.case-name {
						float: left;
					}
					.case-block-toggle {
						float: right;
					}
				}
				.case-block-body {
					clear: both;
					.case {
						display: block;
						clear: both;
						padding: 12px 20px 30px 10px;
						.case-summary {
							float: left;
							color: #5B5B5B;
							width: calc(100% - 80px);
							cursor: pointer;

						}
						.case-options {
							float: right;
							i{
								padding: 0 12px;
								font-size: 16px;
								color: #B2B2B2;
								cursor: pointer;
							}
						}
					}

				}
				&.active {
					max-height: 500px;
					height: initial;
				}
			}
		}
	}
}

#share-case-modal{
	.modal-dialog{
		top: 230px;

		.modal-content {
			background: #F0F2F4;
			border-radius: 0;
			.modal-header {

			}
			.modal-body {
				input{
					width: 75%;
					float: left;
					height: 35px;
					font-size: 14px;
				}
				.copy-to-clipboard {
					width: 25%;
					float: left;
					height: 35px;
					padding: 7px 13px;
					border: 1px solid darkgrey;
					background: #c7c7c7;
					color: #505050;
					transition: 1s;
					cursor: pointer;
					&:hover{
						background:#a09f9f;
						color: #191919;

					}
				}
			}
			.modal-footer {

			}
		}
	}
}
