#bill-form {
margin-top: 28px;
.input-group {
  width: 333px;
  label {
    overflow: hidden;
    &:nth-of-type(1) {
      &::before {
        content: '\f007';
        top: 19px;
      }
        &:nth-of-type(2)::before {
          content: '\f1ad';
          font-weight: 100;
          font-size: 15px;
          top: 68px;
        }
        &:nth-of-type(3)::before {
          content: '\f10b';
          font-weight: 100;
          font-size: 22px;
          top: 113px;
          left: 13.2px;
        }

    }
    &:nth-of-type(2) {
      &:before {
        content: '\f09d';
        font-weight: 100;
        font-size: 14px;
        top: 69px;
        left: 11px;
      }
    }
    &.credit-label {
      input {
      //  &:nth-of-type(1) {
      //  width: 70%;
      //  float: left;
      //  margin-right: 2%;
      //}
      //  &:nth-of-type(2){
      //    width: 28%;
      //    float: left;
      //  }
    }
    }
  }
  input {
    &[type='text'] {
      width: 100%;
    }
  }
  .date-block {
    margin-top: 12px;
    label {
      width: 162px;
      height: 39px;
      margin: 0;
      padding: 0;
      float: left;
    &:nth-of-type(even) {
        float: right;
    }
    &:nth-of-type(1){
      &::before {
        content: '\f073';
        top: 119px;
        left: 11px;
        height: 0;
        width: 0;
        font-weight: 100;
      }
    }
      &:nth-of-type(2){
        &::before {
          content: '\f073';
          top: 119px;
          left: 182px;
          height: 0;
          width: 0;
          font-weight: 100;
        }
      }
      input {
        width: 100%;
        height: 39px;
        color: $body_gray_dark;
        font-weight: 100;
        padding-left: 41px;
      }
      select {
        //remove arrow
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 100%;
        height: 100%;
        border-radius: 0%;
        float: left;
        border: solid 1px $header_background;
        padding-left: 41px;
        font-size: 13px;
        font-weight: 100;
        color: $body_gray_dark;

      }
    }

  }
  &.addressblock {
    input[type=text]{
      margin-top: 12px;
      color: $body_gray_dark;
    }
  }
}
  .billtext {
    margin: 51px 0 5px 0;
    p {
      margin: 0;
    }
  }
  input[type='submit'] {
    width: 111px;
    margin: 56px auto 27px auto;
  }
  .legal {
    width: 307px;
    margin: 0 auto 39px auto;
  }
}