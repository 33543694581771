$header_background: #0076B6;
$sub_header_active: #00A5FF;
$sub_header_text: #6E6E6E;

$heading_gray: #4A4A4A;

$body_gray: #999999;
$body_gray_dark: #666;
$body_gray_light: #CBCECE;

$purple: #144B8C;
$gray: #6A6A6A;
$light-gray: #F8F8F8;
$border: #C3C3C3;
$border_light: #DBDBDB;

$reg-border: #C3C3C3;
$icons: #616161;

$error_background: #C2332C;
$error_border: #8A0000;