.edit-block.branding-block {
  .headCol {
    border: none;
    p {
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }
  }
  .btn-primary {
    border: none;
    width: 201px;
    height: 39px;
    font-weight: 100;
    transition: background-color 1s;
  }
  .color-select {
    margin-top: 5rem;
  }
  .color-block {
    margin-top: 15px;
    color: $body_gray;
    font-weight: 300;
    span {
      position: relative;
      bottom: 7px;
    }
  }
  .prev-text {
    font-size: 12px;
    padding: 0;
    margin: 0 0 5px -15px;
    color: $body_gray_light;
    font-weight: 100;
  }
  .fake-site {
    border: 1px solid $border;
    border-right: none;
    height: 440px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    overflow: hidden;
    .win-bar {
      background-color: $body_gray;
      height: 30px;
      .circles {
        padding: 8px 0 0 10px;
      }
    }
    .fake-head {
      margin: 30px 0 25px 0;
    }
    .fake-content {
      height: 106px;
      border-top: 5px solid #B91F24;
      border-bottom: 5px solid #B91F24;
    }
  }
  .fake-block {
    height: 20px;
    //border: 1px solid #000;
    margin-top: 2rem;
    &.fb-lg {
      margin: 2rem -7px 0 -6px;
    }
  }
  .primary-color {
    background-color: #B91F24;
  }

  .secondary-color {
    background-color: #F1F1F1;
  }

  .primary-text-color {
    background-color: #fff;
  } .prev-calc-row {
    margin: 2rem 0 1rem 0;
  }

	.calculator-branding-previews {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 25px;

		.calculator-preview {
			border: solid 1px #aaa;
			width: 24%;
			padding: 5px;
			margin-bottom: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

}

.reset-branding {
	margin-top: 200px;
}
