#calculator-blocks {
	display: flex;
	flex-wrap: wrap;
	margin-top: 2em;

	.calculator-box {
		width: 25%;
		padding: 5px 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-right: 1px solid #979797;
		a {
			// IE fixes
			min-height: 1px;
			display: block;
			width: 100%;
		}
		&:nth-of-type(4n) {
			border-right: none
		}
		&:last-child{
			border: 0;
		}
		.calculator-logo {
			width: 100%;
			margin: auto;
			display: block;
		}
		.calculator-box-header {
			background-size: contain;
			width: 85%;
			margin:auto;
			height: 100px;
			background-repeat: no-repeat;
		}
		.calculator-box-body {
			align-self: flex-end;
			color: #5B5B5B;
			text-align: center;
			font-size: 12px;
			p{
				line-height: 20px;
			}
		}
		.calculator-box-button {
			text-align: center;
			button {
				background: transparent;
				border: 1px solid #979797;
				border-radius: 0;
				padding: 8px 24px;
				outline: none;
				color: #5B5B5B;
				transition: 1s;
				&:hover{
					background-color: gainsboro;
				}

			}
		}
	}
	hr {
		width: 100%;
		border-top: solid 1px #ddd;
	}

	@media screen and (max-width: $desktop-width) {
		flex-direction: column;
		margin: 0;

		hr {
			display: none;
		}

		.calculator-box {
			padding: 20px;
			padding-bottom: 40px;
			width: 100%;
			border-right: none;
			border-bottom: 1px solid #979797;
			margin: 0;

			&:nth-of-type(3n) {
				border-bottom: 1px solid #979797;
			}
		}
	}

	&.searchable-calculator-list {
		.calculator-box {
			margin-bottom: 10px;
		}
	}

}

.calculator-search-form {
	input {
		width: 50%;
		font-size: 24px;
		padding: 0.25em;
	}
}
