.reg-block {
	&.login-block {
	}

	border: solid 1px $border;
	width: 631px;
	margin: 7rem auto;
	text-align: center;
	padding: 0;
	//overflow: visible;
	overflow: hidden;
	//font-family: Helvetica, san-serif;
	.icon-wrapper {
		width: 100%;
		height: 114px;
		margin: 0 auto;
		border-bottom: solid 1px $border;
		padding: 33px 0 0 0;
		.icon-list {
			height: 57px;
			margin: 0 auto;
			width: 513px;
			padding: 0 0 0 0;
			text-align: center;
			li {
				display: block;
				float: left;
				margin: 0rem 6px;
				width: 45px;
				text-align: center;
				.icon-circle {
					display: inline-block;
					border: 1px solid $reg-border;
					border-radius: 50%;
					padding: 10px 0 0 0;
					height: 34px;
					width: 34px;
					margin: 0 auto;
					transition: background-color 1s, color 1s;
					-webkit-transition: background-color 1s, color 1s;
					-moz-transition: background-color 1s, color 1s;
					&.currentTab {
						background-color: $header_background;
						i {
							color: #fff;
						}
					}
				}

				.fa {
					color: $icons;
					font-size: 11px;
					&.fa-pencil {
						font-size: 12px;
					}
					&.fa-user {
						font-size: 12px;
					}
					&.fa-check {
						font-size: 14px;
					}
				}
				.icon-caption {
					font-size: 11px;
					font-weight: 100;
					margin-top: 8px;
					color: $body_gray_dark;
				}
			}
			.reg-line {
				border-color: $reg-border;
				border-width: 1px;
				width: 50px;
			}
		}
	}
}

.register, .edit-container {
	white-space: normal;
	display: inline-block;
	width: 100%;
	overflow: hidden;
	clear:both;
	z-index: 999;
	h3 {
		color: #4A4A4A;
		font-weight: 100;
	}
	p {
		color: $body_gray_dark;
		font-weight: 300;
		a {
			color: #00A5FF;
		}
	}
	.btn-block {
		width: 334px;
		margin-left: auto;
		margin-right: auto;
	}
	.btn {
		color: #fff;
		margin-top: 1rem;
		width: 100%;
		border-radius: 0;
		text-align: left;
		font-weight: 400;
		height: 38px;
		letter-spacing: 0.3px;
		text-rendering: auto;
		padding: 7px 12px;
		-webkit-font-smoothing: antialiased;
		background-color: $header_background;
		transition: background-color 2s;
		-webkit-transition: background-color 1s;
		-moz-transition: background-color 1s;
		&:hover {
			background-color: #025684;
		}

		&:not([href]):not(input) {
			background-color: #666;
			border-color: #555;
			color: #aaa;
			cursor: auto;
		}

		&:disabled {
			background-color: #666;
			border-color: #555;
			color: #aaa;
			cursor: auto;
		}

		i {
			font-size: 18px;
			margin-right: 5px;
			position: relative;
			top: 1px;

		}
	}
	.email-text {
		width: 334px;
		margin: 0 auto;
		text-align: center;
		hr {
			width: 35px;
			border-color: $header_background;
			display: inline-block;
			float: left;
		}
		p {
			margin: 10px 18px;
			color: $header_background;
			float: left;
			font-size: 14px;
			width: 68%;
			text-align: center;
		}

	}
	.input-group {
		clear: left;
		width: 334px;
		margin: 0 auto;
		label {
			width: 100%;
			display: block;
			margin: 1rem 0 0 0;
			padding: 0;
			overflow: hidden;

			&::before {
				font-family: FontAwesome;
				color: $header_background;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
			}
			&:nth-of-type(1)::before {
				content: '\f0e0';
				display: block;
				position: absolute;
				top: 19px;
				left: 12px;
			}
			&:nth-of-type(2)::before {
				content: '\f023';
				display: block;
				position: absolute;
				font-size: 18px;
				top: 67px;
				left: 12px;
			}
			&:nth-of-type(3)::before {
				content: '\f023';
				display: block;
				position: absolute;
				font-size: 18px;
				top: 116px;
				left: 12px;

			}
			input[type="text"], input[type='password'] {
				width: 100%;
				padding-left: 3.5rem;
				font-weight: 200;
				color: $body_gray_dark;
			}
		}
	}
	input[type="submit"] {
		text-align: center;
		width: 158px;
		background-color: transparent;
		border: 1px solid $header_background;
		color: $header_background;
		transition: color .8s, background-color .8s;
		&:hover {
			color: #fff;
		}

	}
}

#tos-area {
	margin: 20px;
	padding: 20px;
	border: solid 1px $header_background;
}

#attachblock {
	overflow: visible;
	white-space: nowrap;
	height: 605px;

}

.small-link {
  position: relative;
  top: -1.5em;
  display: block;
  font-size: 80%;
}

.g-recaptcha {
  margin-top: 1em;
  display: flex;
  justify-content: center;
}
