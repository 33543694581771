#footer-wrap{
    height: 106px;
    background-color: #4A4A4A;
    bottom: 0px;
    position: relative;
    width: 100%;
    z-index: 100;

    #footer-left-block{
        float: left;
        padding-top: 30px;
        padding-left: 40px;
        width: 300px;
        img{
            width: 41px;
        }
        span{
            font-size: 12px;
            color: white;
            float: right;
            line-height: 21px;
        }
    }
    #footer-right-block{
        float: right;
        padding-top: 30px;
        padding-right: 100px;
        ul{
            float: left;
            list-style: none;
            padding-right: 30px;
            li{
                a{
                    color: white;
                    font-size: 12px;
                    text-decoration: none;
                }
            }
        }
    }
}

.wrapper {
  max-width: $desktop-width - 10px;
  padding: 0 5px;
  margin: 0 auto;

  &.center-children {
    display: flex;
    justify-content: center;
	}

  &.big-wrapper {
    max-width: $desktop-width + 200px;
	}
}

#site-footer {
	position: absolute;
	left: 0;
	right: 0;
  background-color: $brand-color;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: left;
  color: rgba(255,255,255,0.8);
  font-size: 12px;
}
#site-footer a {
  color: rgba(255,255,255,0.8);
}
#site-footer a:hover {
  text-decoration: underline;
}
@media screen and (max-width: $mobile-width) {
  #site-footer {
    padding-bottom: 5px;
  }
}
#site-footer .fb-like {
  margin-right: 5px;
}
#site-footer .wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
}
@media screen and (max-width: $desktop-width) {
  #site-footer .wrapper {
    flex-direction: column-reverse;
  }
}
#site-footer .wrapper .footer-group {
  position: relative;
  flex-grow: 1;
  width: 25%;
  display: flex;
  flex: 1;
  align-items: center;
}
@media screen and (max-width: $desktop-width) {
  #site-footer .wrapper .footer-group {
    width: 100%;
    max-width: 500px;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 1em;
  }
  #site-footer .wrapper .footer-group form {
    padding: 0 1em;
    max-width: 350px;
  }
}
#site-footer .wrapper .footer-group.column {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#site-footer .wrapper .footer-group.column form {
  width: 100%;
  justify-content: space-between;
}
#site-footer .wrapper .footer-group.column form input {
  width: 70%;
}
#site-footer .wrapper .footer-group.column form input.btn,
#site-footer .wrapper .footer-group.column form input.button,
#site-footer .wrapper .footer-group.column form .btn,
#site-footer .wrapper .footer-group.column form .button {
  width: 25%;
}
#site-footer .wrapper .footer-group ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: $desktop-width) {
  #site-footer .wrapper .footer-group ul {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  #site-footer .wrapper .footer-group ul li {
    padding: 0.25em 1em;
  }
}
#site-footer .wrapper .footer-group ul li {
  margin: 0.25em 0;
}
#site-footer .wrapper .footer-group ul li i.fa {
  font-size: 16px;
  margin-right: 0.5em;
}
#site-footer .wrapper .footer-group ul.no-list-style {
  list-style: none;
  margin: 0;
  padding: 0;
}
#site-footer .wrapper #footer-iqc-logo {
  margin-right: 1em;
}
#site-footer .wrapper #footer-iqc-logo img {
  width: 64px;
  height: 64px;
}
#site-footer .wrapper .separator {
  margin: 0 0.5em;
}
