#contact-us {
    padding: 34px;
    text-align: center;
    background-color: #CECECE;
    border-bottom: 15px solid #F0F2F4;
    height: 340px;
    div#contact-us-header {
        font-family: Helvetica;
        font-size: 24px;
        color: #5B5B5B;
        padding-bottom: 5px;

    }
    div#contact-us-body {
        color: #5B5B5B;
        padding-bottom: 15px;
    }
    #contact-us-form{
        max-width: 400px;
        margin: auto;
        input{
            height: 30px;
            width: 100%;
            padding-left: 10px;
            border: 0;
        }
        textarea{
            border: 0;
            width: 100%;
            height: 60px;
            resize: none;
            padding-left: 10px;
        }
        button{
            background: transparent;
            border: 1px solid #5B5B5B;
            color: #5B5B5B;
            font-size: 12px;
            float: right;
            height: 34px;
            width: 68px;
        }
        #contact-us-phone, #contact-us-email{
            float: left;
            width: 50%;
        }
        #contact-us-comments {
            clear: both;
            margin-bottom: 3px;
        }
        .contact-wrap{
            padding: 3px;
        }

    }


}
