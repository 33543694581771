header {
  width: 100%;
  z-index: 100;

  #header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #0076B6;
    min-height: 40px;
    z-index: 105;
    width: 100%;
    max-width: 100vw;
    padding: 10px;

    #login-wrap {
      padding-right: 2vw;
      text-align: center;

      .text {
        display: inline-block;
        font-size: 12px;
        color: white;
        margin-right: 10px;
        margin-bottom: 5px;
      }

      .btn {
        margin-right: 10px;
      }
    }
  }
}
