.page-index{
	#main-wrap{
		min-height: calc(100% - 246px);
	}
	#header-image{
		width: 100%;
		height: 375px;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		border-bottom: 5px solid #CBCECE;
	}
	#coming-soon{
		width: 100%;
		height: 285px;
		text-align: center;
		display: inline-block;
		vertical-align: top;
		padding: 68px 178px;
		.calculator-box-header{
			font-weight: bold;
			color: #5B5B5B;
		}
		@media screen and (max-width: $desktop-width) {
			padding: 10px 20px;
			padding-bottom: 30px;
			height: auto;
		}
	}
	.response {
		margin: 2em 0;
	}
}
