#calculator-form {
  height: 382px;
  margin: 0;
  .calc-block {
    width: 337px;
    height: 342px;
    border-right: solid 1px $border;
    margin: 0;
    float: left;
    padding: 17px 0 0 45px;
    box-sizing: border-box;
    input[type='checkbox'] {
      display: none;
    }
    select { display: none;}
    .calc-select {
        width: 122px;
        height: 106px;
        float: left;
        background-color: #fff;
        border: solid 2px $border;
        box-sizing: border-box;
        margin: 0 0 22px 0px;
        cursor: pointer;
        transition: border .5s;
        -webkit-transition: border .3s;
        -moz-transition: border .3s;
        &:hover {
          border-color: $header_background;
        }

        &:nth-of-type(even) {
          margin-left: 22px;
        }
        &.beartester {
          background-image: url('../img/beartester-logo-small.jpg');
          background-size: 100%;
        }
        &.crutsolver {
          background-image: url('../img/crutsolver-logo-small.png');
          background-size: 100%;
        }
        &.fixedgrowth {
          background-image: url('../img/fixedgrowth-logo-small.jpg');
          background-size: 100%;
        }
        &.selected {
          border-color: $header_background;
          &::after {
            font-family: FontAwesome;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            font-weight: 100;
            display: block;
            content: '\f00c';
            color: #fff;
            font-size: 11px;
            text-align: center;
            background-color: $header_background;
            border-radius: 50%;
            height: 18px;
            width: 18px;
            padding-top: 2px;
            float:right;
            position: relative;
            bottom: 10px;
            left: 8px;
          }
        }

      }
  }
  .desc-block {
    float: left;
    height: 325px;
    width: 291px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    .desc-list {
      text-align: left;
      font-size: 14px;
      margin-left: 38px;
      color: $icons;
      padding: 0 0 0 12px;
      ul {
        margin: 0;
        padding: 0;
        text-indent: 12px;
        font-weight: 300;
        li {
          font-size: 8px;
          line-height: 2;
          span {
            font-size: 14px;
          }
        }
      }
    }
    p {
      font-size: 16px;
      width: 201px;
      font-weight: 100;
      text-align: left;
      margin-left: 35px;
      &:nth-of-type(1) {
        color: $icons;
        font-weight: 300;
      }
      &:nth-of-type(2) {
        color: $header_background;
        margin-top: 38px;
      }
    }
    input[type="submit"] {
      margin-top: 15px;
      width: 216;
    }
  }
}