.page-dev-tools{
	header, #footer-wrap,#sub-header-wrap{
		@extend .fixed-item;
	}
	#sub-header-wrap{
		top:40px;
	}
	#main-wrap{
		overflow: auto;
	}
}
