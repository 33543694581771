// imports go here
@import 'profile.scss';
@import 'billing.scss';
@import 'editbilling.scss';
@import 'scenerio.scss';
@import 'branding.scss';
//global scss for accounts pages


.side-menu {
  ul {
    border: 1px solid $border;
    border-bottom: none;
    color: $icons;
    font-weight: 300;
    li {
      padding: 1.5rem 0 1.5rem 1rem;
      border-bottom: 1px solid $border;
      background-color: #fff;
      div:nth-child(2) {
        padding: 0 12px;
      }
      &.active {
        color: #000;
        background-color: #F4F4F4;
        font-weight: 400;
      }
    }
  }

  .sm-notif {
    border: solid 1px black;
    color: #fff;
    font-size: 12px;
    padding: 0 1px;
    &.blue {
      border-color: #045987;
      background-color: #0076B6;
    }
    &.red {
      border-color: #8A0000;
      background-color: #C2332C;
    }
  }
  a {
    color: inherit;
    &:hover {
      color: #000;
    }
    &:focus {
      color: inherit;
    }
  }
}

.edit-block .headCol {
  border-bottom: 1px solid $border_light;
  color: $body_gray;
  padding-bottom: 2rem;
  h3 {
    margin-bottom: 7px;
    font-family: Helvetica, lato, sans-serif;
    color: $heading_gray;
    font-weight: 300;
  }
  p {
    margin: 0;
  }
}