html, body {
    max-width: 100vw;
    left: 0;
    position: relative;
    margin: auto;
    background-color: #FFFFFF;
    font-family: 'Lato', san-serif;
}

#main-wrap {
    max-width: 1080px;
		padding: 5px;
    margin: auto;
    position: relative;
}

.text-center {
	text-align: center;
}

.bottom-space {
	margin-bottom: 20px;
}

.btn {
    &.btn-white {
        background: transparent;
        border: 1px solid white;
        border-radius: 0px;
        color: white;
        font-size: 12px;
        padding: 2px 11px;

        &:hover {
            background-color: rgba(255, 255, 255, 0.32);
            transition: 1s ease;
        }
    }
    &.btn-blue {
        height: 39px;
        width: 90px;
        border-radius: 0px;
        background: $header_background;
        color: white;
        vertical-align: top;
        font-size: 13px;
        transition: .3s ease;
        &.outline {
            background: transparent;
            border: 1px solid $header_background;
            width: initial;
            font-size: 12px;
            color: $header_background;
        }
        &:hover {
            color: white;
            background-color: #025684;
        }
        &.zeroclipboard-is-hover {
            color: white;
            background-color: #025684;
        }

    }
    &.btn-transparent {
        background: none;
        color: #5B5B5B;
        font-size: 12px;
        padding-left: 0;
        padding-top: 8px;
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }
}

.input-group {
    width: 100%;
    position: relative;
    input {
        height: 39px;
        border: 1px solid $header_background;
        width: calc(100% - 90px);
        padding-left: 10px;
        font-size: 13px;
    }
    button {

    }
}

.fixed-item {
    position: fixed;
    max-width: 1080px;
		padding: 5px;
    width: 100%;
    left: 0;
    z-index: 100;
}

div#hidden-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 150;
    display: none;
}

#copy-status {
    z-index: 1300;
    text-align: center;
    color: white;
    padding-top: 6px;
    bottom: -20%;
    position: fixed;
    width: 100%;
    left: 0;
    height: 65px;
    background: rgba(0, 0, 0, 0.55);
    font-size: 32px;
    transition: 1s ease-in;
    &.active {
        bottom: 0;
    }
}

.modal-open {
    overflow: auto !important;
    padding-right: initial !important;
}

.no-pad {
    padding: 0;
}

.no-pad-left {
    padding-left: 0;
}

.no-pad-right {
    padding-right: 0;
}

.no-float {
    float: none;
}

#floating-login-wrap {
    position: fixed;
    right: 56px;
    top: -100%;
    z-index: 99;
    background: #006fab;
    padding: 5px;
    transition: 1s ease;
    .btn-block{
        text-align: center;
        color: #FFF;
        .btn-primary {
            background: transparent;
            font-size:22px;
            border: none;
            &:hover {
                border: none;
            }
        }
    }
    &.active {
        top: 40px;
    }
    .input-group {
        clear: left;
        width: 50%;
        margin: 0 auto;
        label {
            width: 100%;
            display: block;
            margin: 1rem 0 0 0;
            padding: 0;
            overflow: hidden;

            &::before {
                font-family: FontAwesome;
                color: $header_background;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
            }
            &:nth-of-type(1)::before {
                content: '\f0e0';
                display: block;
                position: absolute;
                top: 19px;
                left: 12px;
            }
            &:nth-of-type(2)::before {
                content: '\f023';
                display: block;
                position: absolute;
                font-size: 18px;
                top: 67px;
                left: 12px;
            }
            &:nth-of-type(3)::before {
                content: '\f023';
                display: block;
                position: absolute;
                font-size: 18px;
                top: 116px;
                left: 12px;

            }

        }

    }
    input[type="email"], input[type='password'] {
        width: 37%;
        padding-left: 10px;
        margin: 6px 7px;
        font-weight: 200;
        color: #666;
        height: 32px;
        float: left;
        font-size: 12px;
    }
    button{
        vertical-align: bottom;
        margin-top: 6px;
        padding: 6px 20px;
    }

}
