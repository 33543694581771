.billing-edit-block.edit-block
{

  .headCol
  {
    margin-bottom: 48px;
    p {
      margin-top: 0;
    }
  }
  .register{
    padding: 0;
    height: auto;
  }

  p {
    color: $body_gray;
  }
  #bill-form {
    margin-top: 1rem;
    .legal {
      margin: 3rem auto 3rem auto;
    }
  }

  .submit-group.buttons {
    margin: 0rem 0 0 0;
    .btn-primary {
      margin: 0;
      width: auto;
      background-color: transparent;
      color: $header_background;
      &:hover {
        background-color: $header_background;
        color: #fff;
        border-color: $header_background;
      }
      &.cancel {
        color: $body_gray;
        border-color: $body_gray;
        &:hover {
          background-color: $body_gray;
          color: #fff;
          border-color: $body_gray;
        }
      }
    }
  }


}