#info-form {
  font-size: 14px;
  color: $body_gray_dark;
  .input-group {
    label {
      &:nth-of-type(1)::before {
        content: '\f007';
        top: 20px;
      }
      &:nth-of-type(2)::before {
        content: '\f1ad';
        font-weight: 100;
        font-size: 15px;
        top: 68px;
      }
      &:nth-of-type(3)::before {
        content: '\f10b';
        font-weight: 100;
        font-size: 22px;
        top: 113px;
        left: 13.2px;
      }
    }
  }

  .input-group.domain-block {
    clear: left;
    width: 333.54px;
    height:70px;
    margin: 45px auto 0 auto;
    //transition: opacity 1s;
    //-webkit-transition: opacity 1s;
    //-moz-transition: opacity 1s;
    &setNo{
      //opacity: 0;
    }
    label {
      margin: 0;
      &::before {
        font-weight: 100;
        font-family: FontAwesome;
        content: '\f0c1';
        font-size: 16px;
        position: absolute;
        top: 39px;
        left: 12px;
        color: $header_background;
      }

      input[type="text"] {
        width: 333.53px;
        height: 39px;
        padding-left: 41px;
        border: 1px solid $header_background;
        font-weight: 100;
      }
    }

  }
}

.fin-block {
  margin: 41px auto 0 auto;
  width: 307px;
  p { margin: 0}
  .info-text {
    margin: 0 auto 15px auto;
    width: 100%;
    padding: 0 10px 0 10px;
  }
  .yesno-block {
    width: 180px;
    height: 39px;
    margin: 0 auto 0px auto;
    div {
      cursor: pointer;
      transition: background-color .5s, color .5s;
    }
    input[type="radio"] {
      display: none;
    }
    div {
      display: block;
      float: left;
      width: 90px;
      height: 39px;
      padding-top: 9px;
      border: solid 1px $header_background;
      text-align: center;
      background-color: #fff;
      &.yes {
        border-right: none;
      }
      &.selected {
        background-color: $header_background;
        color: #fff;
      }
    }

  }
}