#sub-header-wrap {
  position: relative;
  width: 100%;
  z-index: 99;
  background: #FFFFFF;
  min-height: 105px;
  border-bottom: 3px solid #0076B6;
  transition: .3s ease;
  overflow: visible;

  &.under{
    box-shadow:  0px 1px 1px #A9A9A9;
  }

  #sub-header {
    max-width: 1080px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;

    #site-logo {
      text-align: left;
      position: relative;
      flex-grow: 1;

      @media screen and (max-width: $desktop-width) {
        text-align: center;
      }

      img {
        max-height: 100px;
        max-width: 100vw;
        height: auto;
        width: auto;
      }
    }

    #site-menu {
      text-align: center;
      position: relative;
      height: 100px;
      max-width: 100vw;
      flex-grow: 1;

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;

        @media screen and (max-width: $desktop-width) {
          justify-content: center;
        }

        li {
          display: block;
          text-transform: uppercase;
          font-size: 16px;
          padding: 40px 6px;
          border: solid 1px transparent;

					@media screen and (max-width: $desktop-width) {
						&#calculator-menu-item {
							display: none;
						}
					}

					@media screen and (max-width: $mobile-width) {
						padding: 14px 6px;
					}

          &.has-menu {
            position: relative;

            .menu{
              display: none;
            }
          }

          a {
            text-decoration: none;
            color: $sub_header_text;
            &:hover {
              color: #0076B6;
            }

          }

          &.active {
            a {
              color: $sub_header_active;
            }
          }

          &.open {
            border: 1px solid #979797;

            a{
              color: #00A5FF;
            }

            .border-overlay {
              position: absolute;
              right: 79px;
              top: -2px;
              padding: 0 8px;
              background: #FFFFFF;
              height: 5px;
            }

            .menu {
              text-align: left;
              display: block;
              position: absolute;
              right: -80px;
							background-color: #fff;
              top: 99px;
              width: 950px;
              border: 1px solid #979797;
              box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.21);
              z-index: 200;

							@media screen and (max-width: $mobile-width) {
								top: 50px;
							}

              ul {
                float: none;
                padding: 0;

                li {
                  width: 25%;
                  font-size: 14px;
                  display: block;
                  padding: 5px 15px;
                  z-index: 200;
                  background: #FFFFFF;

                  img {
                    max-width: 16px;
                    position: relative;
                    left: -8px;
                    vertical-align: center;
                  }

                  a {
                    color: #666;
                    cursor: pointer;

                    &:hover{
                      color: #0076B6;
                    }
                  }

                  &.active{
                    a{
                      color: #0076B6;

                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }
}
