.billing-block {
  .calc-block {
    width: 100%;
    margin: 0;
    float: left;
    padding: 15px;
    .calc-select-outer {
      width: 50%;
      float: left;
      padding: 15px;
      position: relative;
      .view-calculator-button{
        display: none;
      }
      .activate-text{
        display: block;
        clear: both;
        text-align: center;
      }
      .checkmark{
        position: absolute;
        top: 7px;
        right: 9px;
        z-index: 20;
        .fa-times-circle {
          color: #C3C3C3;
          font-size: 18px;
          background: #FFF;
        }
        .fa-check-circle {
          display:none;
        }
      }
      .calc-select-inner {
        width: 100%;
        background-color: #fff;
        border: solid 2px #C3C3C3;
        cursor: pointer;
        transition: border .5s;
        -webkit-transition: border .3s;
        -moz-transition: border .3s;
        overflow: hidden;
        position: relative;
        padding: 2px 10px 18px 10px;
        height: 110px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .calculator-image {
          width: 145px;
          height: 90px;
          flex-shrink: 1;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          margin: auto;
        }
        .view-calculator-button {
          float: right;
        }
        .btn-primary {
          background-color: #fff;
          border: 1px solid $border;
          color: $header_background;
          text-align: center;
          transition: color 1s, background-color 1s, border-color 1s;
          -webkit-transition: color 1s, background-color 1s, border-color 1s;
          -moz-transition: color 1s, background-color 1s, border-color 1s;
          font-size: 10px;
          margin: 0;
          &:hover {
            color: #fff;
            background-color: $header_background;
            border-color: $header_background;
          }
        }
      }
      &.selected {
        .view-calculator-button{
          display: block;
        }
        .activate-text{
          display: none;
          float: left;
          text-align: initial;
        }
        .calculator-image{
          margin: initial;
          float: left;
        }
        .checkmark{
          position: absolute;
          top: 7px;
          right: 9px;
          z-index: 20;
          .fa-check-circle {
            display: initial;
            color: #0076B6;
            font-size: 18px;
            background: #FFF;
          }
          .fa-times-circle {
            display:none;
          }
        }
        .calc-select-inner {
          border-color: $header_background;
        }
      }
    }
  }

  .footer-block {
    position: relative;
    clear: both;
    display: block;
    overflow: auto;
    padding: 20px 15px;
    button.deactivate-calcs.btn.btn-primary {
      width: initial;
      padding: 11px 18px;
      cursor: pointer;
      float: right;
      border-color: #b1b1b1;
      background: #EFEFEF;
      height: initial;
      margin: 0;
      color: #6b6b6b;
      outline: none;
      &:hover{
        background: #d4d4d4;
      }
    }
  }

  .info-blocks {
    padding: 13px 11px 0 0;

  }
  .info-block {
    margin: 1rem 0 0 0;
    height: 44px;
    width: 112px;
    padding: .5rem 0 0 0;
    color: $body_gray;
    font-size: 14px;
    &.price {

    }
    p {
      margin: 0;
    }
    sub {
      line-height: 4px;
      font-size: 12px;
    }
    .price {
    }
  }
  .inactive {
    opacity: 0.5;
  }
  .img-logo {
    width: 80%;
    margin-left: 12px;
  }
  .buttons {
    padding-right: 26px;
    margin: 4.2rem 0 3.9rem 0;
    .btn-primary {
      background-color: #fff;
      border: 1px solid $border;
      color: $header_background;
      text-align: center;
      transition: color 1s, background-color 1s, border-color 1s;
      -webkit-transition: color 1s, background-color 1s, border-color 1s;
      -moz-transition: color 1s, background-color 1s, border-color 1s;
      &:hover {
        color: #fff;
        background-color: $header_background;
        border-color: $header_background;
      }
    }
    i {
      font-size: 12px;
      &.fa.fa-cog {
        font-size: 13px;
      }
    }
  }

  .error {
    background-color: $error_background;
    border-top: 1px solid $error_border;
    border-bottom: 1px solid $error_border;
    padding: 23px 0 20px 30px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    span {
      font-weight: 500;
    }
    p {
      color: #fff;
      font-weight: 300;
      margin: 0;
      width: 567px;
    }

  }

  .bill-info {
    padding: 30px 0 0 15px;
    p {
      margin: 0;
    }
    a {
      color: #00A5FF;
      text-decoration: none;
      transition: color .8s;
      &:hover {
        color: $header_background;
      }
    }
  }

  &.billing-info-block {
    .buttons {
      margin: 6rem 0 0 0;
      background-color: transparent;
      .btn-primary {
        margin: 0;
        width: auto;
        &.cancel {
          color: $body_gray;
          &:hover {
            background-color: $body_gray;
            color: #fff;
            border-color: $body_gray;
          }
        }
      }

    }
  }
}
.modal-blue{
  .modal-content{
    border-radius: 0;
    border: 2px solid #0076B6;
    top: 140px;
  }
  label{
    font-weight: 500;
    color: #999999;
  }
  h4{
    color: #0076B6;
    font-size: 22px;
  }
  h5 {
    color: #999999;
    font-size: 14px;
  }
  button{
    border-radius: 0;
    font-size: 12px;
    padding: 6px 19px;
    &:first-child{
      float: left;
    }
    &:hover{

    }
  }
  .btn-primary{
    color: #337ab7;
    background: transparent;
    transition: 1s;
    &:hover{
      background: #337ab7;
      color: #FFFFFF;
    }
  }
}

#deactivate-modal {
  .modal-dialog{
    width:406px;
  }
}

#confirmation-modal {
  .modal-dialog {
    width: 460px;
  }
  .modal-header{
    border:none;
  }
  .modal-footer{
    border: none;
  }
}
