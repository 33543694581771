// imports go here


//global scss for accounts pages

.account-content {
  margin-top: 44px;
  .edit-block {
    background-color: #fff;
    border: 1px solid $border;
    padding: 0;
    margin-bottom: 71px;

    h3 {
      font-weight: 500;
    }
    form {
      margin: 0;
    }
  }


  .edit-container {
    padding: 0;
    height: auto;
    z-index: initial;
    .input-group {
      margin: 0;
      label::before {
        font-size: 14px;
      }
      label:nth-of-type(1)::before {
        content: '\f007';
        font-weight: 100;
      }
      label:nth-of-type(2)::before {
        content: '\f1ad';
        font-weight: 100;
      }
      label:nth-of-type(3)::before {
        content: '\f10b';
        font-weight: 100;
        font-size: 18px;
        left: 14px;
      }
      &.domain-block label {
        margin-top: 23px;
        &::before {
          top: 33px;
          content: '\f0c1';
        }
      }

    }
  }
  .fin-block {
    margin: 41px 0 0 0;
    .yesno-block {
      margin: 0;
    }
    .info-text {
      padding: 0;
      font-size: 14px;
      color: $body_gray;
    }
  }
  .fields-group {
    padding-left: 27px;
  }
  .submit-group {
    padding: 18px 0 18px 27px;
    margin: 34px 0 0 0;
    background-color: #F4F4F4;
    border-top: 1px solid $border_light;
    input[type="submit"], .btn-secondary {
      margin: 0;
      padding: 0;
      width: 103px;
      height: 39px;
    }
  }

}